<template>
    <div id="cart-conotainer">
        <div class="cart-content">
            <div class="cart-details">                
                <div class="cart-count">your bag</div>
                <div class="cart-details-item" v-for="(item,index) in cartItemsList" :key="item.id + index">
                    <img :src="item.image" alt="">
                    <div class="cart-details-info">
                        <div class="cart-details-info-content">
                            <div class="top">
                                <div class="cart-title">{{item.productTitle}}</div>
                                <div class="cart-price">${{item.price}}</div>
                            </div>
                            <div class="cart-sku">sku:{{item.sku}}</div>
                            <div class="item-sku" v-if="item.size">size:{{item.size}}</div>
                            <div class="item-sku" v-if="item.color">color:{{item.color}}</div>
                            <div class="action-box">
                                <div class="quantity">
                                    <el-input-number v-model="item.quantity" :min="1" :max="99" size="small" @change="handleChangeCartItem"></el-input-number>
                                </div>
                                <div class="delete" @click="handleDelete(index)">DELETE</div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>

            </div>
            <div class="cart-total-info-content">
                <div class="cart-total-info">
                    <div class="total-title">Order Summary</div>
                    <div class="subtotal">
                        <div class="title">Subtotal:</div>
                        <div class="price">${{total.subTotal}}</div>
                    </div>
                    <div class="total">
                        <div class="title">Total:</div>
                        <div class="price">${{total.totalPrice}}</div>
                    </div>
                    <div class="weight">
                        <div class="title">Total Weight:</div>
                        <div class="value">{{total.totalWeight}}{{total.weightUnit}}</div>
                    </div>
                </div>
                <div class="checkout" @click="goCheckout">CHECKOUT</div>
                
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions , mapGetters} from 'vuex'
import {caculateCartPrice} from '@/api/mall/index.js'
export default {
    data() {
        return {
            total:{
                subTotal:'',
                totalPrice:'',
                totalWeight:'',
                weightUnit:''
            }
        }
    },
    mounted() {
        this.updateCart()
    },
    computed:{
        ...mapGetters([
            "cartItems"
        ]),
        cartItemsList(){
            let [...list] = this.cartItems
            return (
                this.cartItems.map(item=>{
                    if(item.variantTitle.indexOf('-') != -1){
                        item.size = item.variantTitle.split('-')[0]
                        item.color = item.variantTitle.split('-')[1]
                    }else{
                        item.size = item.variantTitle
                    }
                    return item
                })
            )
        }
    },
    methods: {
        handleChangeCartItem(){
            this.updateCart()
            this.changeCart(
                {cartItems:this.cartItems}
            )
        },
        handleDelete(index){
            this.cartItems.splice(index,1)
            this.updateCart()
            this.changeCart(
                {cartItems:this.cartItems}
            )
        },
        updateCart(){
            caculateCartPrice(JSON.stringify({productList:this.cartItems})).then(res=>{
                this.total = res.data.cart
                this.changeWeight({
                    weight:res.data.cart.totalWeight
                })
            })
        },
        goCheckout(){
            this.$router.push({name:'checkout'})
        },
        ...mapActions([
            'changeCart',
            'changeWeight' 
        ])
    }
    
}
</script>

<style lang="stylus" scoped>
#cart-conotainer
    background #fff
    color #222
    padding 1.875rem 13.75% 0
    border-top 1px solid #a0a0a0

    .cart-content
        display flex
        .cart-details
            flex 1
            .cart-details-item
                padding 1.875rem 0
                border-bottom 1px solid #eee
                display flex
                img 
                    max-width 10rem
            .cart-details-info
                flex 1
                margin-left 2.5rem
                position relative
                .top
                    display flex
                    justify-content space-between
                .cart-sku
                    margin-top 1.25rem
                    font-size .875rem
                    color #666
                .item-sku
                    margin-top .3125rem
                    font-size .875rem
                    color #666

            .action-box
                display flex
                justify-content space-between
                align-items center
                position absolute
                width 100%
                bottom 0
                .delete
                    padding 5px 10px
                    border 1px solid #222
                    font-size .875rem                            
.cart-total-info 
    width 18.75rem
    border .0625rem solid #222
    border-radius .25rem
    padding 1.25rem
    margin-left 4rem
    font-size 1rem
    .total-title
        font-size 1.125rem
        margin-bottom 1.2rem
    .subtotal,.total,.weight
        display flex
        justify-content space-between
        margin .625rem 0
.checkout 
    height 2.5rem
    line-height 2.5rem
    text-align center
    margin-top 1.875rem
    background #000
    margin-left 4rem
    color #fff
    font-size .875rem
    font-weight 600
    letter-spacing 1px





</style>
